html {
  --widthLimit: 1250px;
  --heightLimit: 720px;
}

@media only screen and (min-height: 720px) {
  html {
    --useMin: 0;
    --usePercent: 1;
  }
}

@media only screen and (max-height: 720px) {
  html {
    --useMin: 1;
    --usePercent: 0;
  }
}

@media only screen and (max-width: 1250px) {
  html {
    --useMinWidth: 1;
    --usePercentWidth: 0;
  }
}

@media only screen and (min-width: 1250px) {
  html {
    --useMinWidth: 0;
    --usePercentWidth: 1;
  }
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.App {
  font-size: 1.2rem;
  background: #f5f5f5;
  margin: auto;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  min-width: 1250px;
  max-width: calc(100vw - 20px);
}

/*@font-face {
  font-family: SFProLight;
  src: url("https://wsfonts.s3.eu-central-1.amazonaws.com/SF-Pro-Display-Light.woff2")
    format("woff2");
}

@font-face {
  font-family: SFProRegular;
  src: url("https://wsfonts.s3.eu-central-1.amazonaws.com/SF-Pro-Display-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: SFProMedium;
  src: url("https://wsfonts.s3.eu-central-1.amazonaws.com/SF-Pro-Display-Medium.woff2")
    format("woff2");
}

@font-face {
  font-family: SFProSemiBold;
  src: url("https://wsfonts.s3.eu-central-1.amazonaws.com/SF-Pro-Display-Semibold.woff2")
    format("woff2");
}

@font-face {
  font-family: SFProBold;
  src: url("https://wsfonts.s3.eu-central-1.amazonaws.com/SF-Pro-Display-Bold.woff2")
    format("woff2");
} */

@font-face {
  font-family: SFProLight;
  src: url("https://wsfonts.s3.eu-central-1.amazonaws.com/SF-PRO-DISPLAY-LIGHT.OTF")
    format("opentype");
}

@font-face {
  font-family: SFProRegular;
  src: url("https://wsfonts.s3.eu-central-1.amazonaws.com/SF-PRO-DISPLAY-REGULAR.OTF")
    format("opentype");
}

@font-face {
  font-family: SFProMedium;
  src: url("https://wsfonts.s3.eu-central-1.amazonaws.com/SF-PRO-DISPLAY-MEDIUM.OTF")
    format("opentype");
}

@font-face {
  font-family: SFProSemiBold;
  src: url("https://wsfonts.s3.eu-central-1.amazonaws.com/SF-PRO-DISPLAY-SEMIBOLD.OTF")
    format("opentype");
}

@font-face {
  font-family: SFProBold;
  src: url("https://wsfonts.s3.eu-central-1.amazonaws.com/SF-PRO-DISPLAY-BOLD.OTF")
    format("opentype");
} 

.App,
h1,
h2,
h3,
h4,
h5,
h6,
html,
input,
button,
button,
div,
p,
Link,
span {
  font-family: SFProRegular, SFProBold, Arial, Helvetica, sans-serif;
  letter-spacing: 0.5px;
}
